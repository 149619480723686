@media screen and (min-width: 1025px) {
  .mainFooter {
    display: flex;
    flex-flow: column nowrap;
    background-color: $color3;
    opacity: 0.9;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    font-family: $fontThin;
    box-shadow : 0 5px 15px black;
    -webkit-transition: ease .2s;
    -moz-transition: ease .2s;
    -ms-transition: ease .2s;
    -o-transition: ease .2s;
    transition: ease .2s;
    &.closed {
      opacity: 0;
    }
    .footerScroll {
      padding: 25px 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 16pt;
      cursor: pointer;
      -webkit-transition: ease .2s;
      -moz-transition: ease .2s;
      -ms-transition: ease .2s;
      -o-transition: ease .2s;
      transition: ease .2s;
      &:hover {
        text-shadow: 3px 3px 3px #004857;
      }
      i.fas {
        margin-bottom: 10px;
      }
      .labelFooterScroll {
        margin : 0 0 15px 0;
        text-align: center;
      }
    }
    .legalMentions {
      border-top: 1px solid $color2;
      font-size: 10pt;
      padding: 25px 0 10px 0;
      flex: 1;
      line-height: 16pt;
      a:hover {
        color: $color1;
      }
      .bold {
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: 1024px) and (min-width: 681px) {
  .mainFooter {
    display: flex;
    flex-flow: column nowrap;
    background-color: $color3;
    opacity: 0.9;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    font-family: $fontThin;
    box-shadow : 0 5px 15px black;
    -webkit-transition: ease .2s;
    -moz-transition: ease .2s;
    -ms-transition: ease .2s;
    -o-transition: ease .2s;
    transition: ease .2s;
    &.closed {
      opacity: 0;
    }
    .footerScroll {
      padding: 25px 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 16pt;
      cursor: pointer;
      i.fas {
        margin-bottom: 10px;
      }
      .labelFooterScroll {
        margin : 0 0 15px 0;
        text-align: center;
      }
    }
    .legalMentions {
      border-top: 1px solid $color2;
      font-size: 8pt;
      padding: 25px 10px 10px 10px;
      flex: 1;
      line-height: 11pt;
      a:hover {
        color: $color1;
      }
      .bold {
        font-weight: bold;
        text-align: center;
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .mainFooter {
    display: flex;
    flex-flow: column nowrap;
    background-color: $color3;
    opacity: 0.9;
    color: #ffffff;
    align-items: center;
    justify-content: center;
    font-family: $fontThin;
    box-shadow : 0 5px 15px black;
    -webkit-transition: ease .2s;
    -moz-transition: ease .2s;
    -ms-transition: ease .2s;
    -o-transition: ease .2s;
    transition: ease .2s;
    &.closed {
      opacity: 0;
    }
    .footerScroll {
      padding: 15px 0;
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: center;
      flex: 1;
      font-size: 16pt;
      cursor: pointer;
      i.fas {
        margin-bottom: 10px;
      }
      .labelFooterScroll {
        margin : 0 0 5px 0;
        text-align: center;
      }
    }
    .legalMentions {
      border-top: 1px solid $color2;
      font-size: 7pt;
      padding: 25px 5px 10px 5px;
      flex: 1;
      line-height: 10pt;
      a:hover {
        color: $color1;
      }
      .bold {
        font-weight: bold;
        text-align: center;
      }
    }
  }
}