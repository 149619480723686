.flashError {
  color: red;
  text-align: center;
  font-weight: bold;
}

.flashMessage {
  color: blue;
  text-align: center;
  font-weight: bold;
}

@media screen and (min-width: 769px) {
  .contentPage.contact {
    padding-bottom: 150px;
    .headerPage {
      margin-bottom: 0;
      padding-bottom: 0;
      h1 {
        margin-bottom: 15px;
      }
    }
    .bodyPage {
      display: flex;
      flex-flow: row nowrap;
      .colContact {
        display: flex;
        flex-flow: column nowrap;
        margin: 25px;
        flex: 1;
        &.left {
          border-right: 3px solid $color2;
          padding-right: 25px;
        }
        &.pure-form {
          flex: 1;
          padding: 15px;
          button.buttonSubmitForm {
            margin-top: 25px;
            float: right;
          }
          .pure-group {
            &.rgpd {
              display: flex;
              flex-flow: row nowrap;
              margin-top: 20px;
              .item-rgpd {
                display: flex;
                width: auto;
                &.rgpd-input {
                  margin-right: 15px;
                  margin-left: 55px;
                  input {
                    cursor: pointer;
                  }
                }
                &.rgpd-label {
                  font-size: 10pt;
                  flex: 1;
                  label {
                    cursor: pointer;
                    margin: 0;
                    text-align: justify;
                    padding-right: 10px;
                  }
                }
              }
            }
          }
        }
        .colContactSection {
          display: flex;
          flex-flow: column nowrap;
          margin-bottom: 15px;
          p {
            text-align: left;
            font-family: $fontThin;
            font-size: 18pt;
            line-height: 20pt;
            &.picto {
              font-size: 14pt;
              a {
                font-size: 13pt;
                color: $color3;
                &:hover {
                  color: $color2;
                }
              }
            }
            &.social {
              margin: 15px 0 0 0;
              font-size: 22pt;
              letter-spacing: 0.1em;
            }
            .subtext {
              font-style: italic;
              font-size: 14pt;
            }
          }
          #mapLyon {
            height: 350px;
            width: 100%;
            border: 1px solid grey;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) and (min-width: 681px) {
  .contentPage.contact {
    .bodyPage {
      display: flex;
      flex-flow: column nowrap;
      .colContact {
        display: flex;
        flex-flow: column nowrap;
        border-bottom: 3px solid $color2;
        padding-bottom: 25px;
        margin-bottom: 25px;
        &.pure-form {
          button.buttonSubmitForm {
            margin-top: 25px;
            float: right;
          }
          .pure-group {
            &.rgpd {
              display: flex;
              flex-flow: row nowrap;
              margin-top: 15px;
              .item-rgpd {
                display: flex;
                width: auto;
                &.rgpd-input {
                  margin-right: 15px;
                  margin-left: 15px;
                  input {
                    cursor: pointer;
                  }
                }
                &.rgpd-label {
                  font-size: 10pt;
                  flex: 1;
                  label {
                    margin: 0;
                    text-align: justify;
                    padding-right: 10px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .colContactSection {
          display: flex;
          flex-flow: column nowrap;
          margin-bottom: 15px;
          p {
            text-align: left;
            font-family: $fontThin;
            font-size: 18pt;
            line-height: 20pt;
            &.picto {
              font-size: 14pt;
              a {
                font-size: 13pt;
                color: $color3;
                &:hover {
                  color: $color2;
                }
              }
            }
            &.social {
              margin: 15px 0 0 0;
              font-size: 22pt;
              letter-spacing: 0.1em;
            }
            .subtext {
              font-style: italic;
              font-size: 14pt;
            }
          }
          #mapLyon {
            height: 350px;
            width: 100%;
            border: 1px solid grey;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .contentPage.contact {
    .bodyPage {
      display: flex;
      flex-flow: column nowrap;
      .colContact {
        display: flex;
        flex-flow: column nowrap;
        border-bottom: 3px solid $color2;
        padding-bottom: 25px;
        margin-bottom: 25px;
        &.pure-form {
          button.buttonSubmitForm {
            margin-top: 25px;
            width: 100%;
          }
          .pure-group {
            &.rgpd {
              display: flex;
              flex-flow: row nowrap;
              .item-rgpd {
                display: flex;
                width: auto;
                margin-top: 15px;
                &.rgpd-input {
                  margin-right: 15px;
                  margin-left: 15px;
                  input {
                    cursor: pointer;
                  }
                }
                &.rgpd-label {
                  font-size: 10pt;
                  flex: 1;
                  label {
                    margin: 0;
                    text-align: justify;
                    padding-right: 10px;
                    cursor: pointer;
                  }
                }
              }
            }
          }
        }
        .colContactSection {
          display: flex;
          flex-flow: column nowrap;
          margin-bottom: 15px;
          p {
            text-align: left;
            font-family: $fontThin;
            font-size: 18pt;
            line-height: 20pt;
            &.picto {
              font-size: 14pt;
              a {
                font-size: 12pt;
                color: $color3;
                &:hover {
                  color: $color2;
                }
              }
            }
            &.social {
              margin: 15px 0 0 0;
              font-size: 22pt;
              letter-spacing: 0.1em;
            }
            .subtext {
              font-style: italic;
              font-size: 14pt;
            }
          }
          #mapLyon {
            height: 250px;
            width: 100%;
            border: 1px solid grey;
          }
        }
      }
    }
  }
}
