.errorPage {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  h1 {
    padding-left: 4%;
    padding-right: 4%;
  }
  p {
    padding-left: 4%;
    padding-right: 4%
  }
}

@media screen and (min-width: 1025px) {
  .errorPage {
    min-height : 600px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 681px) {
  .errorPage {
    min-height : 400px;
  }
}

@media screen and (max-width: 680px) {
  .errorPage {
    min-height : 300px;
  }
}

