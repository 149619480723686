@media screen and (min-width: 1025px) {
  .bodyPage.honoraires {
    .section {
      display: flex;
      margin-bottom: 55px;
      flex-flow: row nowrap;
      .headerSection {
        display: flex;
        width: 25%;
        min-width: 250px;
        justify-content: center;
        align-items: center;
        border-right: 2px solid $color2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        margin-right: 20px;
        h2 {
          font-family: $fontThin;
          font-size: 22pt;
          font-weight: bold;
          letter-spacing: .1em;
          text-align: center;
        }
      }
      .contentSection {
        width: 75%;
        p {
          font-size: 16pt;
          text-align: justify;
          line-height: 22pt;
          margin-bottom: 25px;
          a {
            color: $color3;
            &:hover {
              color: $color2;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1024px) and (min-width: 681px) {
  .bodyPage.honoraires {
    .section {
      display: flex;
      margin-bottom: 55px;
      flex-flow: row nowrap;
      .headerSection {
        display: flex;
        width: 25%;
        min-width: 250px;
        justify-content: center;
        align-items: center;
        border-right: 2px solid $color2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        margin-right: 20px;
        h2 {
          font-family: $fontThin;
          font-size: 22pt;
          font-weight: bold;
          letter-spacing: .1em;
          text-align: center;
        }
      }
      .contentSection {
        width: 75%;
        p {
          font-size: 16pt;
          text-align: justify;
          line-height: 22pt;
          margin-bottom: 25px;
          a {
            color: $color3;
            &:hover {
              color: $color2;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 680px) {
  .bodyPage.honoraires {
    .section {
      display: flex;
      margin-bottom: 55px;
      flex-flow: column nowrap;
      .headerSection {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 2px solid $color2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        h2 {
          font-family: $fontThin;
          font-size: 16pt;
          font-weight: bold;
          letter-spacing: .1em;
          text-align: center;
        }
      }
      .contentSection {
        p {
          font-size: 14pt;
          text-align: justify;
          line-height: 22pt;
          margin-bottom: 25px;
          a {
            color: $color3;
            &:hover {
              color: $color2;
            }
          }
        }
      }
    }
  }
}

