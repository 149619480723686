@media screen and (min-width: 769px) {
  .contentPage.mentions {
    .headerPage {
      h1 {
        margin-bottom: auto;
      }
    }
    .contentSection {
      margin-bottom: 55px;
      font-size: 16pt;
    }
  }

}

@media screen and (max-width: 768px) and (min-width: 681px) {
  .contentPage.mentions {
  }

}

@media screen and (max-width: 680px) {
  .contentPage.mentions {
  }
}