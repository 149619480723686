.wrapListing {
  background-color: rgba(255,255,255,0.9);
  min-width: 1000px;
  .listing {
    .listingHeader {
      padding: 35px;
      background-color: $color2;
      color: white;
      font-weight: bold;
    }
    .listingContent {
      .searchBox {
        padding: 25px;
      }
      table {
        width: 100%;
        thead {
          background-color: $color3;
          color: white;
          font-weight: bold;
          text-transform: uppercase;
        }
        tr {
          opacity: 1;
          -webkit-transition: ease .1s;
          -moz-transition: ease .1s;
          -ms-transition: ease .1s;
          -o-transition: ease .1s;
          transition: ease .1s;
          &.closed {
           opacity: 0; 
          }
          &.alttr {
            background-color: rgba(255,255,255,0.8);
          }
          td {
            padding: 25px;
            &.center {
              text-align: center;
            }
            span {
              cursor: pointer;
              -webkit-transition: ease .2s;
              -moz-transition: ease .2s;
              -ms-transition: ease .2s;
              -o-transition: ease .2s;
              transition: ease .2s;
              &.treatedStatus {
                color: darkgreen;
                font-size: 16pt;
              }
              &.waitingStatus {
                color: orangered;
                font-size: 16pt;
              }
              &.messageContact {
                font-size: 14pt;
              }
            }
          }
        }
      }
    }
  }
}