@media screen and (min-width: 1025px) {
  .bodyPage.intervention {
    .section {
      display: flex;
      margin-bottom: 55px;
      flex-flow: column nowrap;
      .headerSection {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-bottom: 2px solid $color2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        h2 {
          font-family: $fontThin;
          font-size: 22pt;
          font-weight: bold;
          letter-spacing: .1em;
          text-align: center;
        }
      }
      .contentSection {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: stretch;
        .columnSection {
          display: flex;
          flex-flow: column nowrap;
          justify-content: stretch;
          align-items: stretch;
          flex: 1;
          .blockIntervention {
            display: flex;
            flex-flow: column wrap;
            border: 1px solid lightgrey;
            background: white;
            margin: 10px;
            padding: 15px;
            font-family: $fontThin;
            .headerBlockIntervention {
              font-weight: bold;
              padding-bottom: 15px;
              border-bottom: 1px solid $color2;
              margin-bottom: 10px;
              color: $color3;
              text-align: center;
              i.fas {
                font-size: 34pt;
                margin: 10px;
              }
              h3 {
                margin-top: 10px;
                font-size: 18pt;
              }
            }
            .listBlock {
              display: flex;
              flex-flow: column nowrap;
              p {
                padding: 10px 0;
                font-size: 14pt;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 681px) {
  .bodyPage.intervention {
    .section {
      display: flex;
      margin-bottom: 55px;
      flex-flow: column nowrap;
      .headerSection {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-bottom: 2px solid $color2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        h2 {
          font-family: $fontThin;
          font-size: 20pt;
          font-weight: bold;
          letter-spacing: .1em;
          text-align: center;
        }
      }
      .contentSection {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: stretch;
        .columnSection {
          display: flex;
          flex-flow: column nowrap;
          justify-content: stretch;
          align-items: stretch;
          flex: 1;
          .blockIntervention {
            display: flex;
            flex-flow: column wrap;
            border: 1px solid lightgrey;
            background: white;
            margin: 10px;
            padding: 15px;
            font-family: $fontThin;
            .headerBlockIntervention {
              font-weight: bold;
              padding-bottom: 15px;
              border-bottom: 1px solid $color2;
              margin-bottom: 10px;
              color: $color3;
              text-align: center;
              i.fas {
                font-size: 20pt;
                margin-bottom: 10px;
              }
              h3 {
                font-size: 14pt;
              }
            }
            .listBlock {
              display: flex;
              flex-flow: column nowrap;
              p {
                padding: 5px 0;
                font-size: 12pt;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .bodyPage.intervention {
    .section {
      display: flex;
      margin-bottom: 55px;
      flex-flow: column nowrap;
      .headerSection {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-bottom: 2px solid $color2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        h2 {
          font-family: $fontThin;
          font-size: 16pt;
          font-weight: bold;
          letter-spacing: .1em;
          text-align: center;
        }
      }
      .contentSection {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: stretch;
        .columnSection {
          display: flex;
          flex-flow: column nowrap;
          justify-content: stretch;
          align-items: stretch;
          flex: 1;
          .blockIntervention {
            display: flex;
            flex-flow: column wrap;
            border: 1px solid lightgrey;
            background: white;
            margin: 10px;
            padding: 15px;
            font-family: $fontThin;
            .headerBlockIntervention {
              font-weight: bold;
              padding-bottom: 15px;
              border-bottom: 1px solid $color2;
              margin-bottom: 10px;
              color: $color3;
              text-align: center;
              i.fas {
                font-size: 20pt;
                margin-bottom: 10px;
              }
              h3 {
                font-size: 14pt;
              }
            }
            .listBlock {
              display: flex;
              flex-flow: column nowrap;
              p {
                padding: 5px 0;
                font-size: 12pt;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }
}
