@media screen and (min-width: 1025px) {
  .mainContent {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    margin-top: 100px;
    z-index: 500;
  }
}

@media screen and (max-width: 1024px) and (min-width: 681px) {
  .mainContent {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    margin-top: 100px;
    z-index: 500;
  }
}

@media screen and (max-width: 680px) {
  .mainContent {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    margin-top: 50px;
    z-index: 500;
  }
}


[data-navigation-container] {
  opacity: 1;
  z-index: 500;
  -webkit-transition: ease .2s;
  -moz-transition: ease .2s;
  -ms-transition: ease .2s;
  -o-transition: ease .2s;
  transition: ease .2s;
  &.closed {
    margin-top:-100px;
    opacity: 0;
  }
}