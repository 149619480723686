.adminHeader {
  padding: 15px;
  background-color: $color3;
  text-align: right;
  color: white;
  a {
    font-weight: bold;
    &:hover {
      color: $color2;
      text-decoration: underline;
    }
  }
}

.loginWrapper {
  display: flex;
  flex-flow: row nowrap;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-top: 100px;
  .loginBox {
    background-color: white;
    .loginBoxHeader {
      padding: 25px;
      background-color: $color3;
      color: white;
    }
    .loginBoxContent {
      padding: 35px;
    }
  }
}

#ui-datepicker-div {
  border: 1px solid lightgrey;
  background-color: white;
  border-radius: 5px;
  .ui-datepicker-header {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color3;
    height: 35px;
    color: white;
    font-weight: bold;
  }
  select {
    border: 1px solid lightgrey;
    border-radius: 5px;
    padding: 5px;
    background-color: white;
    color: black;
    &.ui-datepicker-month {
      width: 65px;
      margin-right: 5px;
    }
    &.ui-datepicker-year {
      width: 75px;
    }
  }
  a {
    font-weight: bold;
    &.ui-state-hover {
      background-color: $color2;
    }
    &.ui-state-active {
      background-color: $color3;
      color: white;
    }
  }
}

/* The Modal (background) */
.modal {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  opacity: 0;
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-transition: opacity ease .2s;
  -moz-transition: opacity ease .2s;
  -ms-transition: opacity ease .2s;
  -o-transition: opacity ease .2s;
  transition: opacity ease .2s;
  &.opened {
    opacity: 1;
    z-index: 999999; /* Sit on top */
  }
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
    min-height: 60px;
    &.loading {
      background: url('/romanavocat/styles/images/loader.gif') #ffffff center center no-repeat;
      background-size: 50px;
    }
    .close {
      color: #aaa;
      float: right;
      font-size: 28px;
      font-weight: bold;
      &:hover,
      &:focus {
        color: black;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}




