@media screen and (min-width: 1300px) {
  .wrapMainHeader {
    display: flex;
    position: fixed;
    height: 100px;
    width: 100%;
    max-width: 1960px;
    margin: auto;
    background-color: #ffffff;
    -webkit-box-shadow:  0px 0px 5px #000;
    box-shadow:  0px 0px 5px #000;
    z-index: 999;
    .mainHeader {
      display: flex;
      flex-flow: row nowrap;
      width: 75%;
      min-width: 860px;
      margin: auto;
      height: 100px;
      z-index: 998;
      .mainHeaderTitle {
        .buttonMenuMobile {
          display: none;
        }
        .wrapTitleSite {
          display : flex;
          flex-flow: row nowrap;
          .imgLogoSite {
            height: 80px;
            margin: 10px;
          }
          .siteTitle {
            padding-top: 20px;
            line-height: 80px;
            font-family: $fontTangerine;
            font-size: 34pt;
            padding-right: 25px;
          }
        }
      }
      .wrapMainMenu {
        flex: 1;
        .mainMenu {
          display: flex;
          flex-flow: row;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: center;
          li.mainMenuItem {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 25px;
            padding-right: 25px;
            font-family: $fontMenu;
            letter-spacing: 0.1em;
            font-size: 10pt;
            font-weight: bold;
            height:100px;
            -webkit-transition: ease .1s;
            transition: ease .1s;
            &.currentPageElement {
              background-color: $color3;
            }
            a {
              -webkit-transition: ease .1s;
              transition: ease .1s;
              &.currentPage {
                color: white;
              }
              &:hover {
                color: $color2;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1299px) and (min-width: 681px) {
  .wrapMainHeader {
    display: flex;
    position: fixed;
    height: 100px;
    width: 100%;
    background-color: #ffffff;
    -webkit-box-shadow:  0px 0px 5px #000;
    box-shadow:  0px 0px 5px #000;
    z-index: 999;
    .mainHeader {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      height: 100px;
      align-items: center;
      justify-content: space-between;
      .mainHeaderTitle {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        background: #ffffff;
        height: 100px;
        align-items: center;
        z-index: 1500;
        .buttonMenuMobile {
          display: flex;
          align-items: center;
          justify-content: center;
          -webkit-border-radius:10px;
          -moz-border-radius:10px;
          border-radius: 10px;
          font-size: 40px;
          padding: 10px;
          margin-left: 15px;
        }
        .wrapTitleSite {
          display : flex;
          flex-flow: row nowrap;
          .imgLogoSite {
            height: 60px;
            margin: 20px;
          }
          .siteTitle {
            line-height: 100px;
            font-family: $fontTangerine;
            font-size: 34pt;
            padding-right: 50px;
          }
        }
      }
      .wrapMainMenu {
        position: absolute;
        -webkit-transition: ease .2s;
        -moz-transition: ease .2s;
        -ms-transition: ease .2s;
        -o-transition: ease .2s;
        transition: ease .2s;
        top: 100px;
        left: -100%;
        flex: 1;
        background: #ffffff;
        -webkit-box-shadow:  0px 0px 5px #000;
        box-shadow:  0px 0px 5px #000;
        &.opened {
          left:0;
        }
        .mainMenu {
          display: flex;
          flex-flow: column;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: left;
          li.mainMenuItem {
            padding: 40px 65px;
            font-family: $fontMenu;
            letter-spacing: 0.1em;
            font-size: 14pt;
            font-weight: bold;
            &.logoJustice {
              padding: 0 45px 15px 45px;
              img {
                height: 75px;
              }
            }
            a {
              -webkit-transition: ease .1s;
              transition: ease .1s;
              &.currentPage {
                color: $color3;
              }
              &:hover {
                color: $color2;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .wrapMainHeader {
    display: flex;
    position: fixed;
    height: 50px;
    width: 100%;
    background-color: #ffffff;
    -webkit-box-shadow:  0px 0px 5px #000;
    box-shadow:  0px 0px 5px #000;
    z-index: 999;
    .mainHeader {
      display: flex;
      flex-flow: row nowrap;
      width: 100%;
      height: 50px;
      align-items: center;
      justify-content: space-between;
      .mainHeaderTitle {
        display: flex;
        flex-flow: row nowrap;
        width: 100%;
        background: #ffffff;
        height: 50px;
        align-items: center;
        z-index: 1500;
        .buttonMenuMobile {
          display: flex;
          align-items: center;
          justify-content: center;
          -webkit-border-radius:5px;
          -moz-border-radius:5px;
          border-radius: 5px;
          font-size: 20px;
          padding: 5px;
          margin-left: 15px;
        }
        .wrapTitleSite {
          display : flex;
          flex-flow: row nowrap;
          .imgLogoSite {
            height: 30px;
            margin: 10px;
          }
          .siteTitle {
            line-height: 50px;
            font-family: $fontTangerine;
            font-size: 24pt;
            padding-right: 25px;
          }
        }
      }
      .wrapMainMenu {
        position: absolute;
        -webkit-transition: ease .2s;
        -moz-transition: ease .2s;
        -ms-transition: ease .2s;
        -o-transition: ease .2s;
        transition: ease .2s;
        top: 50px;
        left: -100%;
        flex: 1;
        background: #ffffff;
        -webkit-box-shadow:  0px 0px 5px #000;
        box-shadow:  0px 0px 5px #000;
        &.opened {
          left:0;
        }
        .mainMenu {
          display: flex;
          flex-flow: column;
          flex-wrap: nowrap;
          justify-content: flex-end;
          align-items: left;
          li.mainMenuItem {
            padding: 30px 45px;
            font-family: $fontMenu;
            letter-spacing: 0.1em;
            font-size: 10pt;
            font-weight: bold;
            &.logoJustice {
              padding: 0 45px 15px 45px;
              img {
                height: 45px;
              }
            }
            a {
              -webkit-transition: ease .1s;
              transition: ease .1s;
              &.currentPage {
                color: $color3;
              }
              &:hover {
                color: $color2;
              }
            }
          }
        }
      }
    }
  }
}
