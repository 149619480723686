@media screen and (min-width: 1025px) {
  .contentPage:not(.homepage) {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    .headerPage {
      padding: 15px 0;
      width: 65%;
      min-width: 1024px;
      margin: 0 auto 25px auto;
      h1 {
        padding-bottom: 10px;
        border-bottom: 4px solid $color3;
        font-family: $fontTangerine;
        font-size: 60pt;
        text-align: center;
        margin: 35px auto 55px auto;
      }
      p {
        font-size: 16pt;
        text-align: justify;
        line-height: 26pt;
      }
    }
    .bodyPage {
      display: flex;
      flex-flow: column nowrap;
      padding: 15px 0;
      width: 65%;
      min-width: 1024px;
      margin: auto;
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 681px) {
  .contentPage:not(.homepage) {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    .headerPage {
      margin: 0 10px 25px 10px;
      h1 {
        border-bottom: 4px solid $color3;
        font-family: $fontTangerine;
        font-size: 46pt;
        text-align: center;
        margin: 35px auto 35px auto;
      }
      p {
        font-size: 16pt;
        text-align: left;
        line-height: 22pt;
      }
    }
    .bodyPage {
      display: flex;
      flex-flow: column nowrap;
      padding: 15px;
    }
  }
}

@media screen and (max-width: 680px) {
  .contentPage:not(.homepage) {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    .headerPage {
      margin: 0 10px 25px 10px;
      h1 {
        border-bottom: 4px solid $color3;
        font-family: $fontTangerine;
        font-size: 36pt;
        text-align: center;
        margin: 35px auto 35px auto;
      }
      p {
        font-size: 14pt;
        text-align: justify;
        line-height: 20pt;
      }
    }
    .bodyPage {
      display: flex;
      flex-flow: column nowrap;
      padding: 15px;
    }
  }
}