/*////// GLOBAL //////*/

@media screen and (min-width: 1025px) {}
@media screen and (max-width: 1024px) and (min-width: 681px) {}
@media screen and (max-width: 680px) {}

html {
  overflow-x: hidden;
  max-width: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@media screen and (min-width: 1025px) {
  body {
    background: url('/romanavocat/styles/images/image008big.jpg') #ffffff center center fixed no-repeat;
    background-size: cover;
    font-family: $fontStandard;
    font-size: 12pt;
    max-width: 1960px;
    margin: auto;
    &.intervention {
      background-image: url('/romanavocat/styles/images/image006big.jpg');
    }
  }
}
@media screen and (max-width: 1024px) and (min-width: 681px) {
  body {
    background: url('/romanavocat/styles/images/image008medium.jpg') #ffffff center center fixed no-repeat;
    background-size: cover;
    font-family: $fontStandard;
    font-size: 12pt;
    max-width: 1960px;
    margin: auto;
    &.intervention {
      background-image: url('/romanavocat/styles/images/image006medium.jpg');
    }
  }
}
@media screen and (max-width: 680px) {
  body {
    background: url('/romanavocat/styles/images/image008small.jpg') #ffffff center center fixed no-repeat;
    background-size: cover;
    font-family: $fontStandard;
    font-size: 12pt;
    max-width: 1960px;
    margin: auto;
    &.intervention {
      background-image: url('/romanavocat/styles/images/image006small.jpg');
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.siteMainContainer {
  display : flex;
  position: relative;
  flex-flow: column nowrap;
  z-index: 5000;
  max-width: 1960px;
  margin: auto;
}

@media screen and (min-width: 1025px) {
  .siteMainContainer {
    width: 100%;
    min-width: 400px;
  }
}
@media screen and (max-width: 1024px) and (min-width: 681px) {
  .siteMainContainer {
    width: 100%;
    min-width: 400px;
  }
}
@media screen and (max-width: 680px) {
  .siteMainContainer {
    width: 100%;
  }
}

strong {
  font-weight: bold;
}
