@media screen and (min-width: 1025px) {
  .bannerHomepage {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-end;
    height: 550px;
    background: url('/romanavocat/styles/images/image002big.jpg') bottom center no-repeat;
    background-size: cover;
    color: #ffffff;
    padding-bottom: 65px;
    h1 {
      font-family: $fontTangerine;
      font-size: 85pt;
      text-shadow: 0 0 5px grey;
      text-align: center;
      margin: 25px auto;
    }
    h2 {
      font-family: $fontStandard;
      font-weight: bold;
      font-style: italic;
      font-size: 26pt;
      letter-spacing: .1em;
      text-shadow: 0 0 5px grey;
      padding-top: 25px;
      text-align: center;
    }
  }

  .oneBlockSection {
    display: flex;
    flex-flow: row wrap;
    background-color: #ffffff;
    opacity: 0.9;
    height: 400px;
    .oneBlockDesc {
      display: flex;
      flex-flow: column nowrap;
      width: 75%;
      min-width: 860px;
      margin: auto;
      align-items: center;
      justify-content: center;
      height: 350px;
      flex: 1;
      p {
        padding: 15px;
        font-family: $fontThin;
        font-size: 22pt;
        text-align: center;
      }
    }
  }

  .twoBlockSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 400px;
    .blockSection {
      display: flex;
      flex-flow: column wrap;
      flex: 1;
      opacity: 0.9;
      justify-content: center;
      align-items: center;
      height: 400px;
      p {
        padding: 15px 15%;
        font-family: $fontThin;
        font-size: 20pt;
        line-height: 25pt;
        color: #ffffff;
      }
      &.blockOne {
        background-color: $color3;
        strong {
          color: $color2;
          font-weight: bold;
        }
      }
      &.blockTwo {
        background-color: $color2;
        strong {
          color: $color3;
          font-weight: bold;
        }
      }
    }
  }

  .profileSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    height: 400px;
    .profileImage {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      align-items: center;
      .profilePhoto {
        height: 300px;
        margin: 50px;
        border: 1px solid $color3;
      }
    }
    .profileDesc {
      display: flex;
      flex: 1;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      p {
        padding: 15px 15% 15px 15px;
        text-align: left;
        font-family: $fontThin;
        font-size: 22pt;
        line-height: 26pt;
      }
    }
  }

  .onlyLyonSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 500px;
    background: url('/romanavocat/styles/images/image010big.jpg') top center no-repeat;
    background-size: cover;
  }

  .locationSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    height: 450px;
    .blockSection {
      display: flex;
      flex-flow: column wrap;
      flex: 1;
      opacity: 0.9;
      justify-content: center;
      &.adressSection {
        padding-right: 15px;
        p {
          text-align: right;
          font-family: $fontThin;
          font-size: 22pt;
          line-height: 26pt;
          .subtext {
            font-style: italic;
            font-size: 16pt;
          }
        }
      }
      &.mapSection {
        align-items: flex-start;
        padding-left: 15px;
        #mapLyon {
          height: 350px;
          width: 400px;
          border: 1px solid grey;
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) and (min-width: 681px) {
  .bannerHomepage {
    display: flex;
    flex-flow: column nowrap;
    height: 450px;
    background: url('/romanavocat/styles/images/image002medium.jpg') bottom center no-repeat;
    background-size: cover;
    .backgroundBannerHomepage {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-end;
      flex: 1;
      h1 {
        color: #ffffff;
        font-family: $fontTangerine;
        font-size: 65pt;
        margin: 5px;
        text-align: right;
        padding-top: 45px;
        padding-right: 25px;
        text-shadow: 0 0 5px grey;
      }
      h2 {
        color: #ffffff;
        font-family: $fontStandard;
        font-weight: bold;
        font-size: 22pt;
        letter-spacing: .1em;
        padding-bottom: 25px;
        padding-right: 25px;
        text-align: right;
        text-shadow: 0 0 5px grey;
      }
    }
  }

  .oneBlockSection {
    display: flex;
    flex-flow: row wrap;
    background-color: #ffffff;
    opacity: 0.9;
    height: 400px;
    .oneBlockDesc {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      margin: auto;
      align-items: center;
      justify-content: center;
      height: 350px;
      flex: 1;
      p {
        padding: 15px 15%;
        font-family: $fontThin;
        font-size: 18pt;
        line-height: 22pt;
        text-align: center;
      }
    }
  }

  .twoBlockSection {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    .blockSection {
      display: flex;
      flex-flow: column wrap;
      flex: 1;
      opacity: 0.9;
      justify-content: center;
      align-items: center;
      height: 400px;
      p {
        text-align: center;
        padding: 15px 15%;
        font-family: $fontThin;
        font-size: 18pt;
        line-height: 22pt;
        color: #ffffff;
      }
      &.blockOne {
        background-color: $color3;
        strong {
          color: $color2;
          font-weight: bold;
        }
      }
      &.blockTwo {
        background-color: $color2;
        strong {
          color: $color3;
          font-weight: bold;
        }
      }
    }
  }

  .profileSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    .profileImage {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      width: 100%;
      height: 400px;
      .profilePhoto {
        width: 100%;
      }
    }
    .profileDesc {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      height: 400px;
      width: 100%;
      p {
        text-align: center;
        padding: 15px 15%;
        font-family: $fontThin;
        font-size: 18pt;
        line-height: 22pt;
      }
    }
  }

  .onlyLyonSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 400px;
    background: url('/romanavocat/styles/images/image010medium.jpg') top left no-repeat;
    background-size: cover;
  }

  .locationSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    height: 450px;
    .blockSection {
      display: flex;
      flex-flow: column wrap;
      flex: 1;
      opacity: 0.9;
      justify-content: center;
      &.adressSection {
        padding-right: 15px;
        p {
          text-align: right;
          font-family: $fontThin;
          font-size: 18pt;
          line-height: 22pt;
          .subtext {
            font-style: italic;
            font-size: 14pt;
          }
        }
      }
      &.mapSection {
        align-items: flex-start;
        padding-left: 15px;
        #mapLyon {
          height: 250px;
          width: 350px;
          border: 1px solid grey;
        }
      }
    }
  }
}

@media screen and (max-width: 680px) {
  .bannerHomepage {
    display: flex;
    flex-flow: column nowrap;
    height: 300px;
    background: url('/romanavocat/styles/images/image002small.jpg') bottom right no-repeat;
    background-size: cover;
    .backgroundBannerHomepage {
      display: flex;
      flex-flow: column nowrap;
      align-items: stretch;
      justify-content: flex-end;
      flex: 1;
      h1 {
        color: #ffffff;
        font-family: $fontTangerine;
        font-size: 35pt;
        margin: 5px;
        text-align: right;
        padding-top: 45px;
        padding-right: 15px;
      }
      h2 {
        color: #ffffff;
        font-family: $fontStandard;
        font-weight: bold;
        font-size: 16pt;
        letter-spacing: .1em;
        padding-bottom: 15px;
        text-align: right;
        padding-right: 15px;
      }
    }
  }

  .oneBlockSection {
    display: flex;
    flex-flow: row wrap;
    background-color: #ffffff;
    opacity: 0.9;
    height: 300px;
    .oneBlockDesc {
      display: flex;
      flex-flow: column nowrap;
      width: 100%;
      margin: auto;
      align-items: center;
      justify-content: center;
      height: 300px;
      flex: 1;
      p {
        padding: 15px 15%;
        font-family: $fontThin;
        font-size: 16pt;
        line-height: 22pt;
        text-align: center;
      }
    }
  }

  .twoBlockSection {
    display: flex;
    flex-flow: column wrap;
    width: 100%;
    .blockSection {
      display: flex;
      flex-flow: column wrap;
      flex: 1;
      opacity: 0.9;
      justify-content: center;
      align-items: center;
      height: 300px;
      p {
        text-align: center;
        padding: 15px 15%;
        font-family: $fontThin;
        font-size: 16pt;
        line-height: 22pt;
        color: #ffffff;
      }
      &.blockOne {
        background-color: $color3;
        strong {
          color: $color2;
          font-weight: bold;
        }
      }
      &.blockTwo {
        background-color: $color2;
        strong {
          color: $color3;
          font-weight: bold;
        }
      }
    }
  }

  .profileSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    .profileImage {
      width: 100%;
      .profilePhoto {
        width: 100%;
      }
    }
    .profileDesc {
      display: flex;
      flex-flow: column nowrap;
      justify-content: center;
      align-items: flex-start;
      height: 300px;
      p {
        text-align: center;
        padding: 15px 15%;
        font-family: $fontThin;
        font-size: 16pt;
        line-height: 22pt;
      }
    }
  }

  .onlyLyonSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 300px;
    background: url('/romanavocat/styles/images/image010medium.jpg') top left no-repeat;
    background-size: cover;
  }

  .locationSection {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    padding-top: 25px;
    .blockSection {
      display: flex;
      flex-flow: column wrap;
      width: 100%;
      opacity: 0.9;
      justify-content: center;
      &.adressSection {
        padding-right: 15px;
        padding-bottom: 25px;
        p {
          text-align: right;
          font-family: $fontThin;
          font-size: 16pt;
          line-height: 22pt;
          .subtext {
            font-style: italic;
            font-size: 12pt;
          }
        }
      }
      &.mapSection {
        align-items: flex-start;
        #mapLyon {
          border : 2px solid $color2;
          width: 95%;
          height: 300px;
          margin: auto auto 15px auto;
        }
      }
    }
  }
}

