@media screen and (min-width: 769px) {
  .contentPage.cabinet {
    .section {
      margin-bottom : 25px;
      .headerSection {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-bottom: 2px solid $color2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        h2 {
          font-family: $fontThin;
          font-size: 22pt;
          font-weight: bold;
          letter-spacing: .1em;
          text-align: center;
        }
      }
      .contentSection {
        display: flex;
        flex-flow: row nowrap;
        &.blocLeft {
          .colLeft {
            flex: 1;
          }
          .colRight {
            display: flex;
            flex-flow: column nowrap;
            margin: 0 25px;
            width: 25%;
            min-width: 250px;
            align-items: center;
            justify-content: center;
            float: right;
          }
        }
        &.blocRight {
          .colLeft {
            display: flex;
            flex-flow: column nowrap;
            margin: 0 25px;
            width: 25%;
            min-width: 250px;
            align-items: center;
            justify-content: center;
            float: left;
          }
          .colRight {
            flex: 1;
          }
        }
        .profilePhoto {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 15px;
          img {
            -webkit-box-shadow:  3px 3px 3px grey;
            -moz-box-shadow:  3px 3px 3px grey;
            box-shadow:  3px 3px 3px grey;
            width: 100%;
          }
        }
        .catchphrase {
          display: flex;
          font-style: italic;
          text-align: center;
          justify-content: center;
          align-items: center;
          font-size: 14pt;
        }
      }
      .row {
        font-family: $fontThin;
        font-size: 16pt;
        text-align: justify;
        line-height: 26pt;
        margin-bottom: 25px;
      }
    }
  }

}
@media screen and (max-width: 768px) and (min-width: 681px) {
  .contentPage.cabinet {
    .section {
      margin-bottom : 25px;
      .headerSection {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-bottom: 2px solid $color2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        h2 {
          font-family: $fontThin;
          font-size: 22pt;
          font-weight: bold;
          letter-spacing: .1em;
          text-align: center;
        }
      }
      .contentSection {
        display: flex;
        flex-flow: column nowrap;
        &.blocLeft {
          .colLeft {
            flex: 1;
          }
          .colRight {
            display: none;
          }
        }
        &.blocRight {
          .colLeft {
            display: flex;
            flex-flow: column nowrap;
            margin: 0 25px;
            align-items: center;
            justify-content: center;
          }
          .colRight {
            flex: 1;
          }
        }
        .profilePhoto {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 15px;
          img {
            -webkit-box-shadow:  3px 3px 3px grey;
            -moz-box-shadow:  3px 3px 3px grey;
            box-shadow:  3px 3px 3px grey;
            width: 100%;
          }
        }
        .catchphrase {
          display: flex;
          font-style: italic;
          text-align: center;
          justify-content: center;
          align-items: center;
          font-size: 14pt;
        }
      }
      .row {
        font-family: $fontThin;
        font-size: 16pt;
        text-align: justify;
        line-height: 26pt;
        margin-bottom: 25px;
      }
    }
  }

}

@media screen and (max-width: 680px) {
  .contentPage.cabinet {
    .section {
      margin-bottom : 25px;
      .headerSection {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-bottom: 2px solid $color2;
        padding-bottom: 10px;
        margin-bottom: 20px;
        h2 {
          font-family: $fontThin;
          font-size: 16pt;
          font-weight: bold;
          letter-spacing: .1em;
          text-align: center;
        }
      }
      .contentSection {
        display: flex;
        flex-flow: column nowrap;
        &.blocLeft {
          .colLeft {
            flex: 1;
          }
          .colRight {
            display: none;
          }
        }
        &.blocRight {
          .colLeft {
            display: flex;
            flex-flow: column nowrap;
            margin: 0 25px;
            align-items: center;
            justify-content: center;
          }
          .colRight {
            flex: 1;
          }
        }
        .profilePhoto {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin-bottom: 15px;
          img {
            -webkit-box-shadow:  3px 3px 3px grey;
            -moz-box-shadow:  3px 3px 3px grey;
            box-shadow:  3px 3px 3px grey;
            width: 100%;
          }
        }
        .catchphrase {
          display: flex;
          font-style: italic;
          text-align: center;
          justify-content: center;
          align-items: center;
          font-size: 14pt;
        }
      }
      .row {
        font-family: $fontThin;
        font-size: 16pt;
        text-align: justify;
        line-height: 26pt;
        margin-bottom: 25px;
      }
    }
  }
}